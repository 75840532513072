import React, { useEffect, useState } from 'react';
import './Campaigns.scss';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import TKFilterContainer from '../../../../components/TKFilterContainer/TKFilterContainer';
import TKCardsContainer from '../../../../components/TKCardsContainer/TKCardsContainer';
import TKSliderContainer from '../../../../components/TKSliderContainer/TKSliderContainer';
import TKCard from '../../../../components/TKCard/TKCard';
import { fiveCardsSliderSettings, threeCardsSliderSettings } from '../../../../constants/sliderSettings';
import { formatUrl, handleRedirect } from '../../../../helpers/utilities';
import TKLoaderContainer from '../../../../components/TKLoaderContainer/TKLoaderContainer';
import { getTKTimedCampsRequest } from '../../../../store/actions/campaigns';
import TKCampaignRequestContainer from '../../../../components/TKCampaignRequestContainer/TKCampaignRequestContainer';
import TKPageHeader from '../../../../components/TKPageHeader/TKPageHeader';
const Campaigns = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { timedCamps, loading } = useSelector(state => state.campaigns);
  const info = useSelector((state) => state.auth.userInfo);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedBrands, setSelectedBrands] = useState([]);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fetchData = (list, filterType) => {
    let reqParams = {};
    if (list && list.length > 0) {
      reqParams[filterType] = list;
    }
    dispatch(getTKTimedCampsRequest(reqParams));
  }

  const handleRedirectCampagin = (id, bgColor) => handleRedirect(history, `/kampanyalar/${id}`, { bgColor });

  const handleRedirectShowAll = type => handleRedirect(history, '/kampanyalar/all', { type });

  const prepareCampsTKCard = (key, bgColor, shadow, { _id, image, short_detail, campaign_type, amount, owner }) => (
    <TKCard
      key={key}
      shadow={shadow}
      border={false}
      size="sm"
      badge={true}
      member={owner.entitle}
      bgColor={bgColor}
      image={image}
      desc={short_detail}
      onClick={() => handleRedirectCampagin(formatUrl(short_detail), bgColor)}
      href={`/kampanyalar/${formatUrl(short_detail)}`}
      campaign={{ campaign_type, amount }}
    />
  )

  const colors = ["red", "green", "yellow"];
  const currentList1 = timedCamps?.currentCamps.slice(0, 3).map((camp, i) =>
    prepareCampsTKCard(i, null, true, camp)
  )

  // const currentList2 = timedCamps?.currentCamps.slice(3).map(({_id, image, short_detail, campaign_type, amount}, i) =>
  const currentList2 = timedCamps?.currentCamps.map(({ _id, image, short_detail, campaign_type, amount, owner, cardtype }, i) => {
    if (info?.cardtype === undefined) { //not login
      return (
        <TKCard
          key={i}
          border={false}
          size="sm"
          image={image}
          badge={true}
          member={owner.entitle}
          desc={short_detail}
          onClick={() => handleRedirectCampagin(formatUrl(short_detail))}
          href={`/kampanyalar/${formatUrl(short_detail)}`}
          campaign={{ campaign_type, amount }}
        />
      )
    }
    if (cardtype === undefined) { // campaign for all cards
      return (
        <TKCard
          key={i}
          border={false}
          size="sm"
          image={image}
          badge={true}
          member={owner.entitle}
          desc={short_detail}
          onClick={() => handleRedirectCampagin(formatUrl(short_detail))}
          href={`/kampanyalar/${formatUrl(short_detail)}`}
          campaign={{ campaign_type, amount }}
        />
      )
    }
    if (info?.cardtype === cardtype) { // campaign for specific card
      return (
        <TKCard
          key={i}
          border={false}
          size="sm"
          image={image}
          badge={true}
          member={owner.entitle}
          desc={short_detail}
          onClick={() => handleRedirectCampagin(formatUrl(short_detail))}
          href={`/kampanyalar/${formatUrl(short_detail)}`}
          campaign={{ campaign_type, amount }}
        />
      )
    }

  })

  const colors2 = ["red", "green", "light-blue", "yellow", "dark-blue"];
  let futureList = [];
  const listLength = timedCamps.futureCamps.length;
  for (let i = 0; i < Math.ceil(listLength / 5); i++) {
    for (let j = 0; j < colors2.length; j++) {
      const listIndex = i * colors2.length + j;
      if (listIndex < listLength) {
        const camp = timedCamps.futureCamps[listIndex];
        futureList.push(prepareCampsTKCard(`${i}_${camp._id}`, false, colors2[j], camp))
      }
    }
  }

  const oldList = timedCamps?.oldCamps.map((camp, i) =>
    prepareCampsTKCard(i, "grey", false, camp)
  )

  const notFoundDiv = (pre = "") => {
    return (
      <div
        className="h5 d-flex justify-content-center align-items-center"
        style={{ minHeight: "100px" }}
      >
        {pre} Kampanya Bulunamadı
      </div>
    );
  }

  return (
    <>
      <TKPageHeader header="Kampanyalar" />
      <div>
        <TKFilterContainer
          filterTitle="kampanya"
          selectedCategories={selectedCategories}
          setSelectedCategories={setSelectedCategories}
          brand={true}
          selectedBrands={selectedBrands}
          setSelectedBrands={setSelectedBrands}
          filterHandler={fetchData}
        />
        <div className='container'>
          {/* <div>
        <TKCampaignRequestContainer/>
      </div> */}
          {loading && <TKLoaderContainer />}
          {!loading &&
            <>
              <div className="tk_campaigns-continued">
                {/* <TKCardsContainer
              header="devam eden kampanyalar"
              isShowAll={true}
              showAllOnClick={() => handleRedirectShowAll('current')}
            >
              {currentList1?.length ? currentList1 : notFoundDiv("Devam Eden")}
            </TKCardsContainer> */}
              </div>
              {Boolean(currentList2?.length) &&
                <div className="tk_campaigns-continued-slider">
                  <TKSliderContainer
                    header="devam eden kampanyalar"
                    customSettings={threeCardsSliderSettings(currentList2.length)}
                    sliderClassName="tk_slider-five-shadows tk_slider-padding-shadows"
                    isShowAll={true}
                    showAllOnClick={() => handleRedirectShowAll('current')}
                    placeholder={notFoundDiv("Devam Eden")}
                  >
                    {currentList2}
                  </TKSliderContainer>
                </div>
              }
              <div className="tk_campaigns-future">
                <TKSliderContainer
                  header="gelecek kampanyalar"
                  isShowAll={true}
                  showAllOnClick={() => handleRedirectShowAll('future')}
                  customSettings={threeCardsSliderSettings(futureList.length)}
                  sliderClassName="tk_slider-three-shadows tk_slider-padding-shadows"
                  placeholder={notFoundDiv("Gelecek")}
                >
                  {futureList}
                </TKSliderContainer>
              </div>
              <div>
                <TKSliderContainer
                  header="geçmiş kampanyalar"
                  isShowAll={true}
                  showAllOnClick={() => handleRedirectShowAll('past')}
                  customSettings={threeCardsSliderSettings(oldList.length)}
                  sliderClassName="tk_slider-three-shadows tk_slider-padding-shadows"
                  placeholder={notFoundDiv("Geçmiş")}
                >
                  {oldList}
                </TKSliderContainer>
              </div>

            </>
          }
        </div>

      </div>
    </>
  );
};

export default Campaigns;