import React, { useEffect } from "react";
import "./CampaignDetails.scss";
import { useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import TKCard from "../../../../../components/TKCard/TKCard";
import TKDetailsHeader from "../../../../../components/TKDetailsHeader/TKDetailsHeader";
import TKDetailsContent from "../../../../../components/TKDetailsContent/TKDetailsContent";
import TKButton from "../../../../../components/TKButton/TKButton";
import TKDetailsAddress from "../../../../../components/TKDetailsAddress/TKDetailsAddress";
import { getTKCampsByIdRequest } from "../../../../../store/actions/campaigns";
import TKLoaderContainer from "../../../../../components/TKLoaderContainer/TKLoaderContainer";
import { Button } from "react-bootstrap";
import { formatDate } from "../../../../../helpers/utilities";
import { getTKCardTypesRequest } from "../../../../../store/actions/cardTypes";

const CampaignsDetails = () => {
  const { id } = useParams();
  const { state } = useLocation();
  const dispatch = useDispatch();
  const { displayCamps, loading } = useSelector((state) => state.campaigns);
  const { listCardTypes } = useSelector((state) => state.cardTypes);

  useEffect(() => {
    dispatch(getTKCampsByIdRequest(id));
    dispatch(getTKCardTypesRequest());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const cardtype = listCardTypes.filter(function (item) {
    if (item._id === displayCamps?.cardtype) {
      return item;
    }
  });

  return (
    <>
      {loading && <TKLoaderContainer />}
      {!loading && (
        <div className="container">
          <div className="tk_details-campaign-card">
            <TKCard
              border={false}
              shadow={false}
              badge={true}
              size="auto"
              icon={true}
              bgColor="none"
              image={displayCamps?.image}
              desc={displayCamps?.short_detail}
              campaign={{
                campaign_type: displayCamps?.campaign_type,
                amount: displayCamps?.amount,
              }}
            />
          </div>

          <TKDetailsHeader header={displayCamps?.owner?.entitle}>
            {/* <TKRate rate={4} size="19"/> */}
            {displayCamps?.finish_at && displayCamps?.start_at && (
              <div className="text-right">
                <div style={{ minWidth: "max-content" }}>
                  <div className="d-inline-block" style={{ width: "150px" }}>
                    Başlangıç Tarihi:{" "}
                  </div>
                  {` ${formatDate(new Date(displayCamps?.start_at), "P")}`}
                </div>
                <div style={{ minWidth: "max-content" }}>
                  <div className="d-inline-block" style={{ width: "150x" }}>
                    Son Katılım Tarihi:{" "}
                  </div>
                  {` ${formatDate(new Date(displayCamps?.finish_at), "P")}`}
                </div>
              </div>
            )}
          </TKDetailsHeader>
          <TKDetailsContent
            title="Kampanya Bilgileri"
            desc={displayCamps?.detail}
            cardtype={cardtype[0]?.name}
            max_join_for_holders={displayCamps?.max_join_for_holders}
            max_participant={displayCamps?.max_participant}
          />
          <div className="line"></div>
          <div className="h3">Firma Bilgileri</div>
          <div className="p-3">
            <TKDetailsAddress company={displayCamps?.owner} map={true} />
          </div>
          <div className="line"></div>
          <div className="tk_join-campaign">
            <div className="tk_join-campaign-text">
              Kampanyaya katılmak için indir
            </div>
            <div className="tk_join-campaign-button">
              <a
                target="_blank"
                href="https://play.google.com/store/apps/details?id=tuzlabelediyesi.com.tuzlakartmobil"
              >
                {" "}
                <img
                  className="mr-3"
                  height={50}
                  src="https://tuzlakart.com/images/tuzla-kart-google-play-badge.png"
                  alt="Tuzlalı'nın kartı Mobil uygulama ile Google Play Mağazasında"
                  title="Tuzla Kart "
                  longdesc="https://tuzlakart.com/"
                ></img>
              </a>
              <a
                target="_blank"
                href="https://apps.apple.com/us/app/tuzla-kart/id1458336129?l=tr"
              >
                <img
                  height={50}
                  src="https://tuzlakart.com/images/tuzla-kart-apple-store-badge.png"
                  alt="Tuzlalı'nın kartı Mobil uygulama ile Apple Mağazasında"
                  title="Tuzla Kart "
                  longdesc="https://tuzlakart.com/"
                ></img>
              </a>
            </div>
          </div>
          {/* <TKDetailsContent
            title="firma bilgileri"
            desc={displayCamps?.owner?.detail}
          /> */}
          {/* <div className="tk_display-l">{displayCamps?.owner?.entitle}</div> */}
        </div>
      )}
    </>
  );
};

export default CampaignsDetails;
