export const fiveCardsSliderSettings = itemsLength => ({
  infinite: itemsLength > 5,
  dots: itemsLength > 5,
  autoplay: itemsLength > 5,
  variableWidth: true,
  autoplaySpeed: 15000,
  arrows: true,
  responsive: [
    {
      breakpoint: 992,
      settings: {
        infinite: itemsLength > 4,
        dots: itemsLength > 4,
        autoplay: itemsLength > 4,
      }
    },
    {
      breakpoint: 576,
      settings: {
        infinite: itemsLength > 1,
        dots: false,
        autoplay: itemsLength > 1,
      }
    }
  ]
})

export const fourCardsSliderSettings = itemsLength => ({
  infinite: itemsLength > 4,
  dots: itemsLength > 4,
  autoplay: itemsLength > 4,
  variableWidth: true,
  autoplaySpeed: 15000,
  arrows: true,
  responsive: [
    {
      breakpoint: 992,
      settings: {
        infinite: itemsLength > 3,
        dots: itemsLength > 3,
        autoplay: itemsLength > 3,
      }
    },
    {
      breakpoint: 576,
      settings: {
        infinite: itemsLength > 1,
        dots: false,
        autoplay: itemsLength > 1,
      }
    }
  ]
})

export const threeCardsSliderSettings = itemsLength => ({
  infinite: itemsLength > 3,
  dots: itemsLength > 3,
  autoplay: itemsLength > 3,
  variableWidth: true,
  autoplaySpeed: 18000,
  arrows: true,
  responsive: [
    {
      breakpoint: 992,
      settings: {
        infinite: itemsLength > 2,
        dots: itemsLength > 2,
        autoplay: itemsLength > 2,
      }
    },
    {
      breakpoint: 576,
      settings: {
        infinite: itemsLength > 1,
        dots: false,
        autoplay: itemsLength > 1,
      }
    }
  ]
})